/* Default styles */
.faq-container {
  background-image: url('../assets/img/bg2.png');
  background-size: cover;
  color: #fff;
  min-height: 100vh;
  margin-top: 10rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.faq-title {
  margin-bottom: 3rem;
  font-size: 3.5rem;
  color: #e9ecef;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  font-weight: bold;
}

.faq-intro {
  font-size: 1.6rem;
  margin-bottom: 4rem;
  color: #dee2e6;
  opacity: 0.9;
  font-weight: bold;
}

.faq-heading {
  font-size: 1.5rem;
  font-weight: bold;
}

.faq-text {
  text-align: justify;
  max-width: 1200px;
  margin: auto;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 4rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
}

.faq-item {
  margin-bottom: 2rem;
}

.faq-answer {
  margin-left: 20px;
  margin-top: 7px;
  white-space: pre-wrap; /* Ensure proper wrapping */
}

a {
  color: #17a2b8;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #138496;
}

/* Desktop styles */
@media (min-width: 1200px) {
  .faq-container {
    padding: 4rem;
  }

  .faq-title {
    font-size: 4rem;
  }

  .faq-intro {
    font-size: 2rem;
  }

  .faq-heading {
    font-size: 1.8rem;
  }

  .faq-text {
    padding: 5rem;
    font-size: 1.4rem;
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .faq-title {
    font-size: 2.5rem;
  }

  .faq-intro {
    font-size: 1.2rem;
  }

  .faq-heading {
    font-size: 1.2rem;
  }

  .faq-text {
    padding: 2rem;
    font-size: 1rem;
  }

  .faq-item {
    margin-bottom: 1.5rem;
  }

  .faq-answer {
    margin-left: 15px;
    margin-top: 5px;
    white-space: pre-wrap;
  }
}

@media (max-width: 480px) {
  .faq-title {
    font-size: 2rem;
  }

  .faq-intro {
    font-size: 1rem;
  }

  .faq-heading {
    font-size: 1rem;
  }

  .faq-text {
    padding: 1rem;
    font-size: 0.9rem;
  }

  .faq-item {
    margin-bottom: 1rem;
  }

  .faq-answer {
    margin-left: 10px;
    margin-top: 3px;
    white-space: pre-wrap;
  }
}

/* Word spacing fix */
@media (max-width: 768px) {
  .faq-title {
    font-size: 2.5rem;
  }

  .faq-intro {
    font-size: 1.2rem;
  }

  .faq-heading {
    font-size: 1.2rem;
  }

  .faq-text {
    padding: 2rem;
    font-size: 1rem;
    text-align: left; /* Adjust alignment for smaller screens */
  }

  .faq-item {
    margin-bottom: 1.5rem;
  }

  .faq-answer {
    margin-left: 15px;
    margin-top: 5px;
    word-spacing: normal; /* Ensure normal word spacing */
    letter-spacing: normal; /* Ensure normal letter spacing */
    line-height: 1.5; /* Set a standard line height */
  }
}

@media (max-width: 480px) {
  .faq-title {
    font-size: 2rem;
  }

  .faq-intro {
    font-size: 1rem;
  }

  .faq-heading {
    font-size: 1rem;
  }

  .faq-text {
    padding: 1rem;
    font-size: 0.9rem;
    text-align: left; /* Adjust alignment for smaller screens */
  }

  .faq-item {
    margin-bottom: 1rem;
  }

  .faq-answer {
    margin-left: 10px;
    margin-top: 3px;
    word-spacing: normal; /* Ensure normal word spacing */
    letter-spacing: normal; /* Ensure normal letter spacing */
    line-height: 1.5; /* Set a standard line height */
  }
}
