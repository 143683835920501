.network-section {
  background-image: url('../assets/img/bg1.png');
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: center center; /* Centers the image in the section */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  text-align: center;
  padding: 30px 0; /* Initial padding optimized for mobile */
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.map-image {
  padding-top: 5%;
  width: 90%; /* Adaptive width for better responsiveness */
  max-height: 50vh; /* Lower max-height to better fit mobile screens */
  object-fit: contain; /* Prevents distortion of the image */
}

.network-details {
  display: flex;
  flex-direction: column; /* Start with vertical layout for mobile */
  justify-content: center;
  align-items: center;
  width: 90%; /* Responsive width for mobile */
  max-width: 1200px;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

.network-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Space between items when stacked */
}

.icon {
  width: 50%; /* Larger relative width for better visibility on mobile */
  height: auto;
}

.network-info h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.network-info p {
  margin: 0;
}

@media (min-width: 768px) {
  .network-section {
    padding: 50px 0; /* Increased padding for larger screens */
  }

  .map-image {
    width: 90%; /* Adjust width as needed for desktop */
    max-height: 60vh;
  }

  .network-details {
    flex-direction: row; /* Horizontal layout for larger screens */
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }

  .network-info {
    margin-bottom: 0; /* Remove bottom margin */
  }

  .icon {
    width: 20vw; /* Smaller relative width on larger screens */
  }
}

@media (max-width: 480px) {
  .icon {
    width: 40%; /* Adjust icon size for very small screens */
  }

  .network-info h3,
  .network-info p {
    font-size: smaller; /* Adjust font size for better readability */
  }
}
