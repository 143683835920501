/* SignupForm.css */
input,
select,
button {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  cursor: text;
  z-index: 1000;
}

.signup-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 5%; /* Padding on the sides */
  box-sizing: border-box; /* Ensures padding is included in width/height calculations */
  background-image: url('../assets/img/bg2.png'); /* Background image */
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Modern font */
}

.signup-content {
  display: flex;
  max-width: 46.875rem; /* 750px, Adjusted for better form fit */
  width: 100%;
  align-items: center;
  background: #ffffff; /* Pure white for better contrast */
  padding: 2rem; /* Responsive padding */
  border-radius: 0.75rem; /* 12px, Soft rounded corners */
  box-shadow: 0 0.75rem 1.5rem rgba(0, 0, 0, 0.15); /* More prominent shadow for 3D effect */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for shadow */
  z-index: 10;
}

.signup-form {
  width: 100%; /* Use full width of the container */
  margin: auto; /* Center the form in the container */
  color: #333; /* Ensuring text color is set for older browsers */
}

.signup-form form {
  display: flex;
  flex-direction: column;
  margin: auto; /* Center the form */
}

.signup-form .input,
.signup-form .select {
  margin-bottom: 1rem; /* Standard spacing */
  padding: 0.8rem 1rem; /* 0.8rem 1rem */
  border: 0.125rem solid #e3e6f0; /* 2px, Slightly thicker border for focus */
  border-radius: 0.5rem; /* 8px, Updated border radius */
  font-size: 1rem; /* Standard font size */
  background-color: #fafafa; /* Slightly off white for inputs to pop out */
  transition: border 0.3s ease, box-shadow 0.3s ease; /* Adding transition for shadow */
}

.signup-form .input:focus,
.signup-form .select:focus {
  border-color: #007bff; /* Highlight color on focus */
  box-shadow: 0 0 0 0.1875rem rgba(0, 123, 255, 0.25); /* 3px, Focus shadow */
  outline: none; /* Remove default focus outline */
}

.name-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem; /* Space between first name and last name inputs */
}

/* Individual name input */
.name-input {
  flex: 1; /* Each name input will take up 50% of the container width */
}

/* Style the first name input to have a margin on the right */
.name-container > .name-input:first-child {
  margin-right: 0.5rem; /* Half the gap size to balance spacing on both sides */
}

/* Style for last name input to optionally add margin on the left */
.name-container > .name-input:last-child {
  margin-left: 0.5rem; /* Half the gap size to balance spacing on both sides */
}

.payment-methods {
  margin: 1.4rem 0;
}

.payment-methods h3 {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: left;
}

.payment-methods p {
  /* margin-bottom: 1rem; */
  font-size: 1rem;
  text-align: left;
}

.payment-input-container {
  margin-bottom: 1rem; /* Add space between each payment method */
}

.payment-input-container label {
  display: block; /* Make the label take the full width for alignment */
  margin-bottom: 0.5rem; /* Space between the label and the input field */
  font-size: 1rem; /* Match font size to other form labels */
  font-weight: bold; /* Bold label text for clarity */
}

.input.payment-input {
  padding: 0.8rem 1rem; /* 0.8rem 1rem */
  border: 0.125rem solid #e3e6f0; /* 2px */
  border-radius: 0.5rem; /* 8px */
  font-size: 1rem;
  background-color: #fafafa;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.input.payment-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.1875rem rgba(0, 123, 255, 0.25); /* 3px */
  outline: none;
}

.signup-form .button {
  margin-top: 0.625rem; /* 10px */
  margin-bottom: 1rem;
  padding: 1rem 1.5rem; /* 1rem 1.5rem */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.5rem; /* 8px */
  cursor: pointer;
  font-weight: bold; /* Ensuring text is bold */
  transition: background-color 0.2s, transform 0.1s; /* Adjusted for better feel */
}

.signup-form .button:hover,
.signup-form .button:focus {
  background-color: #0056b3;
  transform: translateY(-0.1875rem); /* -3px, Increased lift effect */
}

.button.disabled {
  background-color: #ccc; /* Grey out the button */
  color: #666;
  cursor: not-allowed;
}

.loading {
  color: #333;
  font-size: 1rem; /* 16px */
  margin-top: 0.625rem; /* 10px, Spacing from the button */
}

/* Checkbox Container */
.checkbox-container {
  display: flex;
  justify-content: flex-start; /* Align to the start of the container */
  gap: 0.625rem; /* 10px, Adjust space between checkboxes */
  margin-bottom: 1rem; /* Space below the checkboxes before the submit button */
  flex-wrap: wrap; /* Wrap the content to avoid overflow */
}

/* Checkbox Label */
.checkbox-label {
  display: flex;
  align-items: center; /* Vertically center the text and checkbox */
  font-size: 0.95rem; /* Slightly smaller font size for subtlety */
  cursor: pointer; /* Indicates clickable items */
  white-space: nowrap; /* Keep the label text on one line */
}

/* Styling for checkbox inputs */
.checkbox-label input[type='checkbox'] {
  margin-right: 0.5rem; /* Space between checkbox and label text */
  cursor: pointer; /* Indicates clickable items */
}

.error-message {
  color: red;
  font-size: 1rem; /* 16px */
  margin-bottom: 1.2rem;
  padding-top: 8.125rem; /* 130px, Add padding to push content below the navbar */
  margin-top: -8.125rem; /* -130px, Negative margin to pull the content back up to its original position */
}

/* Spinner CSS */
.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Media Queries */

/* Mobile */
@media (max-width: 576px) { /* 35.9375rem */
  .signup-section {
    padding: 7rem 1rem;
  }

  .signup-content {
    flex-direction: column;
    text-align: left;
    padding: 1.5rem; /* Smaller padding for smaller screens */
    width: 95%;
  }

  .signup-form {
    width: 100%;
  }

  .checkbox-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .checkbox-label {
    margin-bottom: 0.5rem;
  }

  .name-container {
    flex-direction: column;
    gap: 0
  }

  .name-input {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .peace-avatar {
    width: 80vw !important;
  }
}

/* Tablet */
@media (min-width: 577px) and (max-width: 1024px) { /* 36rem to 64rem */
  .signup-section {
    padding: 7rem 4rem;
  }
  
  .signup-content {
    padding: 2rem;
  }

  .signup-form {
    width: 100%;
  }

  .checkbox-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .checkbox-label {
    margin-bottom: 0.5rem;
  }

  .peace-avatar {
    width: 80vw !important;
  }
}

/* Laptop and Desktop */
@media (min-width: 1025px) { /* 64.0625rem */
  .signup-content {
    padding: 2rem;
  }

  .signup-form {
    width: 100%;
  }

  .checkbox-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .checkbox-label {
    margin-bottom: 0.5rem;
  }

  .peace-avatar {
    width: 30vw !important;
  }
}
