/* waitlistmainForm.css */
input,
select,
button {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  cursor: text;
  z-index: 1000;
}

.waitlistmain-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 5%; /* Padding on the sides */
  box-sizing: border-box; /* Ensures padding is included in width/height calculations */
  background-image: url('../assets/img/bg2.png'); /* Background image */
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Modern font */
}

.waitlistmain-content {
  display: flex;
  max-width: 46.875rem; /* 750px, Adjusted for better form fit */
  width: 100%;
  align-items: center;
  background: #ffffff; /* Pure white for better contrast */
  padding: 2rem; /* Responsive padding */
  border-radius: 0.75rem; /* 12px, Soft rounded corners */
  box-shadow: 0 0.75rem 1.5rem rgba(0, 0, 0, 0.15); /* More prominent shadow for 3D effect */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for shadow */
  z-index: 10;
}

.waitlistmain-form {
  width: 100%; /* Use full width of the container */
  margin: auto; /* Center the form in the container */
  color: #333; /* Ensuring text color is set for older browsers */
}

.waitlistmain-form form {
  display: flex;
  flex-direction: column;
  margin: auto; /* Center the form */
}

.waitlistmain-form .input,
.waitlistmain-form .select {
  margin-bottom: 1rem; /* Standard spacing */
  padding: 0.8rem 1rem; /* 0.8rem 1rem */
  border: 0.125rem solid #e3e6f0; /* 2px, Slightly thicker border for focus */
  border-radius: 0.5rem; /* 8px, Updated border radius */
  font-size: 1rem; /* Standard font size */
  background-color: #fafafa; /* Slightly off white for inputs to pop out */
  transition:
    border 0.3s ease,
    box-shadow 0.3s ease; /* Adding transition for shadow */
}

.waitlistmain-form .input:focus,
.waitlistmain-form .select:focus {
  border-color: #007bff; /* Highlight color on focus */
  box-shadow: 0 0 0 0.1875rem rgba(0, 123, 255, 0.25); /* 3px, Focus shadow */
  outline: none; /* Remove default focus outline */
}

.name-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem; /* Space between first name and last name inputs */
}

/* Individual name input */
.name-input {
  flex: 1; /* Each name input will take up 50% of the container width */
}

/* Style the first name input to have a margin on the right */
.name-container > .name-input:first-child {
  margin-right: 0.5rem; /* Half the gap size to balance spacing on both sides */
}

/* Style for last name input to optionally add margin on the left */
.name-container > .name-input:last-child {
  margin-left: 0.5rem; /* Half the gap size to balance spacing on both sides */
}

.payment-methods {
  margin: 1.4rem 0;
}

.payment-methods h3 {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: left;
}

.payment-methods p {
  /* margin-bottom: 1rem; */
  font-size: 1rem;
  text-align: left;
}

.payment-input-container {
  margin-bottom: 1rem; /* Add space between each payment method */
}

.payment-input-container label {
  display: block; /* Make the label take the full width for alignment */
  margin-bottom: 0.5rem; /* Space between the label and the input field */
  font-size: 1rem; /* Match font size to other form labels */
  font-weight: bold; /* Bold label text for clarity */
}

.input.payment-input {
  padding: 0.8rem 1rem; /* 0.8rem 1rem */
  border: 0.125rem solid #e3e6f0; /* 2px */
  border-radius: 0.5rem; /* 8px */
  font-size: 1rem;
  background-color: #fafafa;
  transition:
    border 0.3s ease,
    box-shadow 0.3s ease;
}

.input.payment-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.1875rem rgba(0, 123, 255, 0.25); /* 3px */
  outline: none;
}

.waitlistmain-form .button {
  margin-top: 0.625rem; /* 10px */
  margin-bottom: 1rem;
  padding: 1rem 1.5rem; /* 1rem 1.5rem */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.5rem; /* 8px */
  cursor: pointer;
  font-weight: bold; /* Ensuring text is bold */
  transition:
    background-color 0.2s,
    transform 0.1s; /* Adjusted for better feel */
}

.waitlistmain-form .button:hover,
.waitlistmain-form .button:focus {
  background-color: #0056b3;
  transform: translateY(-0.1875rem); /* -3px, Increased lift effect */
}

.button.disabled {
  background-color: #ccc; /* Grey out the button */
  color: #666;
  cursor: not-allowed;
}

.loading {
  color: #333;
  font-size: 1rem; /* 16px */
  margin-top: 0.625rem; /* 10px, Spacing from the button */
}

/* Checkbox Container */
.checkbox-container {
  display: flex;
  justify-content: flex-start; /* Align to the start of the container */
  gap: 0.625rem; /* 10px, Adjust space between checkboxes */
  margin-bottom: 1rem; /* Space below the checkboxes before the submit button */
  flex-wrap: wrap; /* Wrap the content to avoid overflow */
}

/* Checkbox Label */
.checkbox-label {
  display: flex;
  align-items: center; /* Vertically center the text and checkbox */
  font-size: 0.95rem; /* Slightly smaller font size for subtlety */
  cursor: pointer; /* Indicates clickable items */
  white-space: nowrap; /* Keep the label text on one line */
}

/* Styling for checkbox inputs */
.checkbox-label input[type='checkbox'] {
  margin-right: 0.5rem; /* Space between checkbox and label text */
  cursor: pointer; /* Indicates clickable items */
}

.error-message {
  color: red;
  font-size: 1rem; /* 16px */
  margin-bottom: 1.2rem;
  padding-top: 8.125rem; /* 130px, Add padding to push content below the navbar */
  margin-top: -8.125rem; /* -130px, Negative margin to pull the content back up to its original position */
}

/* Spinner CSS */
.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.submission-message {
  text-align: center;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.95); /* Semi-transparent white */
  border-radius: 1rem; /* Rounder corners */
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); /* Stronger shadow for a pop effect */
  max-width: 40rem; /* Restrict width */
  margin: auto; /* Center align */
  font-family: 'Roboto', sans-serif; /* Modern and clean font */
  color: #333; /* Softer black text color */
}

/* Headline Styling */
.submission-message h1 {
  font-size: 2.5rem; /* Bigger headline */
  color: #007bff; /* Bright blue for emphasis */
  margin-bottom: 1.5rem;
  font-weight: 700; /* Bold text */
  line-height: 1.2; /* Comfortable spacing */
}

/* Paragraph Styling */
.submission-message p {
  font-size: 1.1rem; /* Slightly larger text for readability */
  color: #555; /* Softer gray */
  margin-bottom: 1rem;
  line-height: 1.6; /* Improved readability */
}

/* List Styling */
.submission-message ul {
  list-style-type: none; /* Remove default bullets */
  margin: 2rem 0;
  padding: 0;
  color: #333; /* Darker color */
}

.submission-message ul li {
  position: relative;
  padding-left: 1.5rem; /* Space for custom icon */
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.submission-message ul li:before {
  content: '✓'; /* Checkmark icon */
  position: absolute;
  left: 0;
  top: 0;
  color: #28a745; /* Green for positive tone */
  font-weight: bold;
  font-size: 1.2rem;
}

/* Link Styling */
.submission-message a {
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}

.submission-message a:hover {
  color: #0056b3; /* Darker blue for hover effect */
  text-decoration: underline; /* Highlight link on hover */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media Queries */

/* Mobile Styles */
@media (max-width: 576px) {
  /* Section padding */
  .waitlistmain-section {
    padding: 2rem 1rem;
  }

  /* Message Card */
  .submission-message {
    padding: 2rem 1rem; /* Reduced padding for smaller screens */
    border-radius: 0.5rem; /* Slightly smaller corners */
    max-width: 90%; /* Take up 90% of the screen width */
  }

  /* Headline */
  .submission-message h1 {
    font-size: 1.8rem; /* Smaller heading */
    margin-bottom: 1rem; /* Reduced space below */
    line-height: 1.3; /* Compact line spacing */
  }

  /* Paragraph */
  .submission-message p {
    font-size: 1rem; /* Adjusted for readability */
    line-height: 1.4;
    margin-bottom: 0.8rem; /* Compact spacing between paragraphs */
  }

  /* List Items */
  .submission-message ul li {
    font-size: 0.9rem; /* Smaller list items */
    line-height: 1.3; /* Compact spacing */
    padding-left: 1.2rem; /* Adjusted padding */
  }

  .submission-message ul li:before {
    font-size: 1rem; /* Slightly smaller checkmark */
  }
}

/* Tablet Styles */
@media (min-width: 577px) and (max-width: 1024px) {
  /* Section padding */
  .waitlistmain-section {
    padding: 3rem 2rem;
  }

  /* Message Card */
  .submission-message {
    padding: 3rem 2rem; /* Slightly larger padding */
    max-width: 70%; /* Constrain width */
  }

  /* Headline */
  .submission-message h1 {
    font-size: 2.2rem; /* Slightly larger heading */
  }

  /* Paragraph */
  .submission-message p {
    font-size: 1rem; /* Standardize font size */
    line-height: 1.5;
  }

  /* List Items */
  .submission-message ul li {
    font-size: 1rem;
  }

  .submission-message ul li:before {
    font-size: 1.2rem;
  }
}

/* Laptop and Desktop */
@media (min-width: 1025px) {
  /* 64.0625rem */
  .waitlistmain-content {
    padding: 2rem;
  }

  .waitlistmain-form {
    width: 100%;
  }

  .checkbox-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .checkbox-label {
    margin-bottom: 0.5rem;
  }

  .peace-avatar {
    width: 30vw !important;
  }
}
