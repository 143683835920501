@keyframes floatBackground {
  0% {
    background-position: center 0;
  }
  50% {
    background-position: center 1%;
  }
  100% {
    background-position: center 0;
  }
}

/* Base Style for About Us Banner */
.about-us-banner {
  position: relative;
  text-align: center;
  background-image: url('../assets/img/bg1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh; /* Maintains full height */
  display: flex;
  align-items: center;
  justify-content: center;
  animation: floatBackground 10s ease-in-out infinite;
}

.about-us-banner-content {
  max-width: 75rem; /* 1200px */
  margin: 0 auto; /* Centers the content */
  padding: 0 1.875rem; /* 30px - Starting padding for mobile-first */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.about-us-banner h1 {
  font-size: 1.75rem; /* Mobile-first font size */
  margin-bottom: 0.9375rem; /* 15px */
  font-weight: bold;
}

.about-us-banner p {
  margin-top: 10px;
  font-size: 1rem; /* Starting font size for mobile */
  margin-bottom: 20px; /* Increased bottom margin for mobile */
}

.about-info-button {
  position: absolute;
  bottom: 5%;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.about-info-button .arrow {
  margin-top: 10px;
  width: 24px;
  height: 24px;
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
  margin-left: 42%;
}

.about-info-button:hover .arrow {
  transform: rotate(45deg) translateY(5px);
}

/* Responsive Adjustments for Increasing Screen Sizes */

/* Mobile Portrait */
@media (max-width: 576px) {
  .about-us-banner h1 {
    font-size: 2rem;
  }

  .about-us-banner p {
    font-size: 1rem;
  }

  .about-us-banner-button {
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
  }

  .about-info-button {
    font-size: 1rem;
  }

  .about-info-button .arrow {
    width: 1rem;
    height: 1rem;
  }
}

/* Tablet Portrait (577px to 1024px) */
@media (min-width: 577px) and (max-width: 1024px) {
  .about-us-banner h1 {
    font-size: 3rem;
    margin-bottom: 1.5rem; /* 20px */
    width: 100%;
  }

  .about-us-banner p {
    font-size: 1.5rem;
    margin-bottom: 1.5rem; /* 30px */
    width: 90%;
  }

  .about-us-banner-buttons {
    flex-direction: row; /* Side-by-side buttons on larger screens */
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
  }

  .about-us-banner-button {
    padding: 0.75rem 1.5rem; /* 12px 24px */
    font-size: 1.1rem;
  }
}

/* Laptop and Desktop (1025px and above) */
@media (min-width: 1025px) {
  .about-us-banner-content {
    padding: 0 5rem; /* 80px */
  }

  .about-us-banner h1 {
    font-size: 4rem;
    margin-bottom: 1.25rem; /* 20px */
  }

  .about-us-banner p {
    font-size: 1.8rem;
    margin-bottom: 1.25rem; /* 40px */
  }

  .about-us-banner-button {
    padding: 0.8rem 1.8rem;
    font-size: 1.3rem;
    flex-direction: row;
    margin-top: 1rem;
  }
}

/* Landscape Mode for Mobile */
@media (max-width: 576px) and (orientation: landscape) {
  .about-us-banner h1 {
    font-size: 1.5rem;
  }

  .about-us-banner p {
    font-size: 0.9rem;
    width: 80%;
  }

  .about-us-banner-button {
    padding: 0.4rem 1.2rem;
    font-size: 0.7rem;
  }

  .about-info-button {
    font-size: 0.9rem;
  }

  .about-info-button .arrow {
    width: 0.9rem;
    height: 0.9rem;
  }
}

/* Landscape Mode for Tablets */
@media (min-width: 577px) and (max-width: 1024px) and (orientation: landscape) {
  .about-us-banner h1 {
    margin-top: 10%;
    font-size: 2.2rem;
    margin-bottom: 0.5rem; /* 16px */
  }

  .about-us-banner p {
    font-size: 0.8rem;
    margin-bottom: 1.0rem; /* 24px */
    width: 85%;
  }

  .about-us-banner-button {
    padding: 0.7rem 1.3rem; /* 11px 21px */
    font-size: 0.9rem;
  }

  .about-info-button {
    font-size: 0.8rem;
  }

  .about-info-button .arrow {
    width: 0.8rem;
    height: 0.8rem;
  }
}
