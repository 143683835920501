/* Default styles */
.subject-container {
  background-image: url('../assets/img/bg2.png');
  background-size: cover;
  color: #fff;
  min-height: 100vh;
  margin-top: 10rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.subject-title {
  margin-bottom: 3rem;
  font-size: 3.5rem;
  color: #e9ecef;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  font-weight: bold;
}

.subject-intro {
  font-size: 1.6rem;
  margin-bottom: 4rem;
  color: #dee2e6;
  opacity: 0.9;
}

.subject-heading {
  font-size: 1.5rem;
  font-weight: bold;
}

.subject-text {
  text-align: justify;
  max-width: 1200px;
  margin: auto;
  background: rgba(79, 23, 114, 0.06);
  border-radius: 10px;
  padding: 4rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
}

.subject-text p {
  margin-bottom: 2rem;
}

a {
  color: #17a2b8;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #138496;
}

/* Desktop styles */
@media (min-width: 1200px) {
  .subject-container {
    padding: 4rem;
  }

  .subject-title {
    font-size: 4rem;
  }

  .subject-intro {
    font-size: 2rem;
  }

  .subject-heading {
    font-size: 1.8rem;
  }

  .subject-text {
    padding: 5rem;
    font-size: 1.4rem;
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .subject-title {
    font-size: 2.5rem;
  }

  .subject-intro {
    font-size: 1.2rem;
  }

  .subject-heading {
    font-size: 1.2rem;
  }

  .subject-text {
    padding: 2rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .subject-title {
    font-size: 2rem;
  }

  .subject-intro {
    font-size: 1rem;
  }

  .subject-heading {
    font-size: 1rem;
  }

  .subject-text {
    padding: 1rem;
    font-size: 0.9rem;
  }
}