@keyframes floatBackground {
  0% {
    background-position: center 0;
  }
  50% {
    background-position: center 1%;
  }
  100% {
    background-position: center 0;
  }
}

.media-banner {
  position: relative;
  text-align: center;
  background-image: url('../assets/img/bg1.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: floatBackground 10s ease-in-out infinite;
}

.media-banner-content {
  position: relative;
  z-index: 2;
  max-width: 75rem; /* 1200px */
  margin: 0 auto;
  padding: 0 0.3125rem; /* 5px */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Fill the parent's height */
}

.media-banner h1 {
  font-size: 5rem; /* Large text */
  margin-bottom: 1.25rem; /* 20px */
  font-weight: bold;
}

.media-banner p {
  font-size: 1.5rem;
  margin-bottom: 5rem; /* 80px */
}

.media-banner-button {
  height: auto;
  justify-content: center;
  padding: 0.625rem 1.25rem; /* 10px 20px */
  font-size: 1rem;
  color: #120349dd; /* A color that contrasts lightly with the background */
  background-color: #e0e0e0; /* Light background color typical for neumorphism */
  border: none;
  border-radius: 0.625rem; /* 10px */
  transition: box-shadow 0.3s ease;
}

.media-banner-button:hover,
.media-banner-button:focus {
  color: #505050;
  box-shadow:
    inset 0.3125rem 0.3125rem 0.625rem #a7a7a7, /* 5px 5px 10px */
    inset -0.3125rem -0.3125rem 0.625rem #ffffff; /* -5px -5px 10px */
}

/* Mobile (up to 576px) */
@media (max-width: 576px) {
  .media-banner-content {
    padding: 0 1.875rem; /* 30px */
  }

  .media-banner h1 {
    font-size: 1.8rem;
    margin-bottom: 0.9375rem; /* 15px */
  }

  .media-banner p {
    font-size: 1rem;
    margin-bottom: 1.5625rem; /* 25px */
  }

  .media-banner-buttons {
    flex-direction: column;
  }

  .media-banner-button {
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
  }
}

/* Tablet (577px to 1024px) */
@media (min-width: 577px) and (max-width: 1024px) {
  .media-banner h1 {
    font-size: 2.6rem;
    margin-bottom: 1.25rem; /* 20px */
  }

  .media-banner p {
    font-size: 1.5rem;
    margin-bottom: 1.875rem; /* 30px */
    width: 85%;
  }

  .media-banner-buttons {
    flex-direction: row; /* Side-by-side buttons on larger screens */
  }

  .media-banner-button {
    padding: 0.75rem 1.5rem; /* 12px 24px */
    font-size: 1.1rem;
  }
}

/* Laptop and Desktop (1025px and above) */
@media (min-width: 1025px) {
  .media-banner-content {
    padding: 0 5rem; /* 80px */
  }

  .media-banner h1 {
    font-size: 4rem;
    margin-bottom: 1.25rem; /* 20px */
  }

  .media-banner p {
    font-size: 1.8rem;
    margin-bottom: 2.5rem; /* 50px */
  }

  .media-banner-button {
    padding: 0.8rem 1.8rem;
    font-size: 1.3rem;
  }
}

/* Landscape Mode for Mobile */
@media (max-width: 576px) and (orientation: landscape) {
  .media-banner-content {
    padding: 0 1.5rem; /* Adjust padding */
  }

  .media-banner h1 {
    font-size: 1.5rem;
    margin-bottom: 0.9375rem; /* 15px */
  }

  .media-banner p {
    font-size: 0.9rem;
    margin-bottom: 1.5625rem; /* 25px */
    width: 90%;
  }

  .media-banner-buttons {
    flex-direction: column;
  }

  .media-banner-button {
    padding: 0.5rem 1rem;
    font-size: 0.7rem;
  }
}

/* Landscape Mode for Tablets */
@media (min-width: 577px) and (max-width: 1024px) and (orientation: landscape) {
  
  .media-banner-content {
    padding: 0 2rem; /* Adjust padding */
  }

  .media-banner h1 {
    margin-top: 10%;
    font-size: 2.2rem;
    margin-bottom: 1rem; /* 16px */
  }

  .media-banner p {
    font-size: 0.8rem;
    margin-bottom: 1.5rem; /* 24px */
    width: 90%;
  }

  .media-banner-buttons {
    flex-direction: row;
  }

  .media-banner-button {
    padding: 0.6rem 1.2rem; /* 10px 20px */
    font-size: 0.6rem;
    width: 6rem;  }
}
