.testimonial-section {
  background-image: url('../assets/img/bg2.png');
  background-size: cover;
  text-align: center;
  padding: 20px;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonial-section h2 {
  margin-top: 5%;
  margin-bottom: 5%;
  color: #fff;
}

.slick-slider {
  width: 80%;
}

.slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-card {
  background-image: url('../assets/img/bg2.png');
  color: white;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
}

.testimonial-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 4%;
  width: 100%;
}

.testimonial-text p, .testimonial-text h5 {
  margin: 10px 0;
  width: 100%;
  text-align: center;
}

div.testimonial-section .testimonial-text p {
  font-size: 1.2rem;
}

div.testimonial-section .testimonial-text h5 {
  font-size: 1rem;
}

.slick-prev,
.slick-next {
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  left: -30px;
}

.slick-next {
  right: -30px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px;
  color: white;
}

/* General adjustments for smaller screens */
@media (max-width: 768px) {
  .testimonial-section {
    padding: 10px !important;
  }
  .testimonial-text p {
    font-size: 1rem !important;
  }

  .testimonial-section h2 {
    margin-bottom: 10%;
  }

  .slick-slider {
    width: 90%;
  }

  .testimonial-card {
    padding: 15px;
    min-height: 150px;
  }

  div.testimonial-section .testimonial-text p {
    font-size: 1rem;
  }

  div.testimonial-section .testimonial-text h5 {
    font-size: 0.8rem;
  }
}

/* Landscape adjustments for iPad mini (768px width in portrait, 1024px width in landscape) */
@media (orientation: landscape) and (max-width: 1024px) {
  .testimonial-section {
    padding: 10px !important;
  }

  .testimonial-text p {
    font-size: 1.2rem !important;
  }

  .testimonial-text h5 {
    font-size: 1rem !important;
  }

  .slick-slider {
    width: 90%;
  }

  .testimonial-card {
    padding: 20px;
    min-height: 200px;
  }
}

/* Portrait adjustments for iPad mini */
@media (orientation: portrait) and (max-width: 768px) {
  .testimonial-section {
    padding: 10px !important;
  }

  .testimonial-text p {
    font-size: 1.2rem !important;
  }

  .testimonial-text h5 {
    font-size: 1rem !important;
  }

  .slick-slider {
    width: 90%;
  }

  .testimonial-card {
    padding: 20px;
    min-height: 200px;
  }
}

/* Landscape adjustments for tablets */
@media (orientation: landscape) and (min-width: 769px) and (max-width: 1024px) {
  .slick-slider {
    width: 90%;
  }

  .testimonial-text p {
    font-size: 1.4rem !important;
  }

  .testimonial-text h5 {
    font-size: 1rem !important;
  }

  .testimonial-card {
    padding: 20px;
    min-height: 200px;
  }
}

/* Landscape adjustments for larger screens */
@media (orientation: landscape) and (min-width: 1025px) {
  .slick-slider {
    width: 80%;
  }

  .testimonial-text p {
    font-size: 1.5rem !important;
  }

  .testimonial-text h5 {
    font-size: 1.2rem !important;
  }

  .testimonial-card {
    padding: 40px;
    min-height: 250px;
  }
}

/* Mobile adjustments */
@media (max-width: 575px) {
  .testimonial-section {
    padding: 3rem !important;
  }
  .testimonial-text p {
    font-size: 1rem !important;
  }


  }
