/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 1.125rem 0; /* 18px */
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: background-color 0.3s ease-in-out; /* Transition for background color only */
}

nav.navbar.scrolled {
  background-color: #121212; /* Background for scrolled navbar */
  padding: 0.625rem 0; /* 10px, Reduced padding when scrolled for a tighter navbar */
}

nav.navbar a.navbar-brand {
  width: auto; /* Adjusted for responsiveness */
  padding-left: 0.5rem; /* 8px, Smaller padding on smallest screens */
}

nav.navbar .navbar-toggler {
  padding: 1rem; /* 16px, More padding for a larger clickable area */
  border: none;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.8px;
  padding: 0.4rem 0.625rem; /* 0.4rem 10px, Smaller padding for compact space */
  font-size: 1rem; /* 16px, Smaller font size for limited screen space */
  transition: opacity 0.3s ease-in-out; /* Smooth transition for hover effects */
}

.navbar-logo {
  width: 4rem;
  height: auto; /* This will keep the aspect ratio of your image */
}

span.navbar-text {
  display: flex;
  align-items: center;
  padding-right: 1rem; /* 16px, Right padding for alignment with the navbar toggler */
}

.social-icon {
  display: inline-block;
  margin-left: 0.875rem; /* 14px */
}
.social-icon a {
  width: 2.625rem; /* 42px */
  height: 2.625rem; /* 42px */
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 0.375rem; /* 6px */
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
}
.social-icon a::before {
  content: '';
  width: 2.625rem; /* 42px */
  height: 2.625rem; /* 42px */
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 0.75rem 1.25rem; /* 12px 20px, Smaller button for mobile devices */
  font-size: 1rem; /* 16px, Adjusted font size for readability */
  margin-left: 1.125rem; /* 18px */
  position: relative;
  background-color: transparent;
  border-radius: 0.3125rem; /* 5px */
  transition: all 0.3s ease-in-out;
}

/* Customizing the hamburger menu icon for a pale white color */
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.75)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 991px) {

  .navbar-logo{
    width: 3rem;
    height: auto;
  }
  /* Ensuring navbar links are aligned to the right on mobile */
  .navbar-nav {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .navbar-text {
    justify-content: center !important;
    padding-top: 0;
    padding-right: 0 !important;
  }

  .navbar-text button {
    margin: 0;
  }
}

/* Tablet */
@media (min-width: 992px) and (max-width: 1024px) {
  .navbar-nav {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  .navbar-text {
    justify-content: flex-end !important;
    padding-top: 0;
    padding-right: 1rem !important; /* 16px */
  }

  .navbar-text button {
    margin-left: 1.125rem; /* 18px */
    padding: 0.875rem 1.75rem; /* 14px 28px */
    font-size: 1.125rem; /* 18px */
  }
}

/* Laptop and Desktop */
@media (min-width: 1025px) {
  nav.navbar a.navbar-brand {
    padding-left: 1rem; /* 16px, Larger padding for larger screens */
  }

  nav.navbar .navbar-nav .nav-link.navbar-link {
    padding: 0 0.9375rem; /* 15px, Larger padding for better spacing */
    font-size: 1rem; /* 16px, Adjusted font size for better readability */
  }

  .navbar-text button {
    padding: 1.125rem 2.125rem; /* 18px 34px, Restore larger padding for larger screens */
    font-size: 1.125rem; /* 18px, Restore larger font size for readability */
  }
}


/************ Footer Css ************/

* {
  box-sizing: border-box;
}

#footerlogo {
  width: 10rem;
  height: auto;
  margin-bottom: 20px; /* Spacing for mobile view */
}

.footer {
  padding: 30px 0;
  background-color: #121212;
  color: #b8b8b8;
  margin-top: 0;
}

.footer-top {
  margin-bottom: 20px; /* Spacing between rows */
}

.footer-links a {
  color: #b8b8b8;
  text-decoration: none;
  padding: 0 10px; /* Spacing between links */
  font-size: 14px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.social-icon img {
  width: 30px; /* Adjust the size of the icons as needed */
  margin: 0 5px; /* Spacing between icons */
  vertical-align: middle;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.arrow-icon img {
  animation: blink 2s infinite;
}

/* Mobile */
@media (max-width: 576px) { /* 35.9375rem */
  .footer-links {
    margin-top: 20px; /* Spacing for mobile view */
    display: block;
    text-align: center; /* Center footer links on mobile */
  }
  .social-icon {
    margin-top: 20px; /* Spacing for mobile view */
    text-align: center; /* Center social icons on mobile */
  }
  .footer p {
    text-align: center; /* Center text on mobile */
  }
  #footerlogo {
    display: none; /* Hide logo on mobile */
  }
}

/* Tablet */
@media (min-width: 577px) and (max-width: 1024px) { /* 36rem to 64rem */
  .footer-links {
    margin-top: 20px; /* Spacing for tablet view */
    display: flex;
    justify-content: center; /* Align footer links in a single line */
    text-align: center; /* Center footer links on tablet */
  }
  .social-icon {
    margin-top: 20px; /* Spacing for tablet view */
    text-align: center; /* Center social icons on tablet */
  }
  .footer p {
    text-align: center; /* Center text on tablet */
  }
}

/* Laptop and Desktop */
@media (min-width: 1025px) { /* 64.0625rem */
  .footer-links {
    margin-top: 0; /* Default spacing for larger screens */
    display: inline-block; /* Align footer links horizontally */
  }
  .social-icon {
    margin-top: 0; /* Default spacing for larger screens */
    text-align: left; /* Align social icons to the left */
  }
  .footer p {
    text-align: right; /* Align text to the right */
  }
  #footerlogo {
    display: block; /* Show logo on larger screens */
    margin-left: 0; /* Default alignment for larger screens */
    margin-right: 0;
  }
}

button {
  font-weight: 500;
}

/* Landscape Mode for Mobile */
@media (max-width: 576px) and (orientation: landscape) {
  .footer-links {
    margin-top: 20px; /* Spacing for mobile view */
    display: block;
    text-align: center; /* Center footer links on mobile */
  }
  .social-icon {
    margin-top: 20px; /* Spacing for mobile view */
    text-align: center; /* Center social icons on mobile */
  }
  .footer p {
    text-align: center; /* Center text on mobile */
  }
  #footerlogo {
    display: none; /* Hide logo on mobile */
  }
}

/* Landscape Mode for Tablets */
@media (min-width: 577px) and (max-width: 1024px) and (orientation: landscape) { /* 36rem to 64rem */

  #footerlogo {
    display: none; /* Hide logo on mobile */
  }

  .footer-links {
    margin-top: 20px; /* Spacing for tablet view */
    display: flex;
    justify-content: center; /* Align footer links in a single line */
    text-align: center; /* Center footer links on tablet */
  }
  .social-icon {
    margin-top: 20px; /* Spacing for tablet view */
    text-align: center; /* Center social icons on tablet */
  }
  .footer p {
    text-align: center; /* Center text on tablet */
  }
}
