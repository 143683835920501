/* General styles for the media section container */
.media-container {
  width: 100%; /* Ensure it spans the full width */
  min-height: 100vh; /* Minimum height to cover the viewport height */
  padding: 60px;
  display: flex;
  flex-wrap: wrap; /* Allow multiple rows */
  justify-content: space-around; /* Distribute space around items */
  align-items: flex-start; /* Align items at the start to handle varying heights */
  background-image: url('../assets/img/bg2.png');
  background-size: cover;
  background-position: center;
}

/* Styles for heading specific to media section */
.media-text-center {
  text-align: center;
  width: 100%; /* Ensure the title spans full width */
  margin-top: 20px;
  margin-bottom: 70px;
}

/* Specific card styling for media section to make it visually appealing */
.media-card {
  border-radius: 10px;
  width: calc(30.00% - 20px); /* Adjust width to fit three cards per row */
  height: 400px; /* Fixed height for all cards */
  margin-bottom: 30px; /* Space around each card */
  margin-left: 10px; /* Space around each card */
  margin-right: 10px; /* Space around each card */
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  background: #ffffff; /* Card background color */
  overflow: hidden; /* Hide overflow */
}

.media-card:hover {
  transform: translateY(-10px);
}

/* Image styles specific to media section */
.media-card-img-top {
  height: 200px; /* Fixed height for consistency */
  width: 100%; /* Ensure the image covers the card width */
  background-size: contain; /* Ensure the whole image is visible */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-color: #fff; /* Optional: set a background color to fill empty space */
}

/* Card body styling specific to media section */
.media-card-body {
  padding: 15px;
  flex-grow: 1; /* Allows the body to expand and fill the available space */
  overflow-y: auto; /* Enables scrolling for overflow content */
}

/* Title within the media card */
.media-card-title {
  color: #007bff; /* Theme color for titles */
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

/* Text within the media card */
.media-card-text {
  font-size: 1rem;
  color: #666; /* Lighter text color for the body text */
}

.media-text-muted {
  font-size: 0.8rem;
  color: #888; /* Even lighter for less important text (like dates) */
  margin-top: 15px; /* Add margin to the top of the date */
}

/* Mobile */
@media (max-width: 576px) { /* 35.9375rem */
  .media-card {
    width: calc(100% - 20px); /* Adjust card width on small devices */
  }
  .media-text-center {
    font-size: 1.5rem; /* Adjust font size for mobile */
    margin-top: 10px;
    margin-bottom: 40px;
  }
}

/* Tablet */
@media (min-width: 577px) and (max-width: 1024px) { /* 36rem to 64rem */
  .media-card {
    width: calc(50% - 20px); /* Adjust card width on medium devices */
  }
  .media-text-center {
    font-size: 2rem; /* Larger font size for tablet */
    margin-top: 15px;
    margin-bottom: 50px;
  }
}

/* Laptop and Desktop */
@media (min-width: 1025px) { /* 64.0625rem */
  .media-card {
    width: calc(30.00% - 20px); /* Default width for larger screens */
  }
  .media-text-center {
    font-size: 2.5rem; /* Larger font size for desktop */
    margin-top: 20px;
    margin-bottom: 70px;
  }
}
