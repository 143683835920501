/* Common Styles */
@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: white; }
}

.main-banner {
  color: white;
  position: relative;
  text-align: center;
  background-image: url('../assets/img/bg1.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: floatBackground 10s ease-in-out infinite;
}

.main-banner-content {
  position: relative;
  z-index: 2;
  max-width: 75rem; /* 1200px */
  margin: 0 auto;
  padding: 0 1.875rem; /* 30px */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.main-banner h1 {
  overflow: hidden;
  font-size: 1.75rem;
  white-space: nowrap;
  margin-bottom: 0.9375rem; /* 15px */
  border-right: 0.15em solid white;
  font-weight: bold;
}

.caret {
  display: inline-block;
  width: 0.03em;
  background-color: white;
  margin-left: 0.125rem; /* 2px */
  animation: blink-caret 1s step-end infinite;
}

.main-banner p {
  font-size: 0.95rem;
  margin-bottom: 1.5625rem; /* 25px */
  width: 100%;
}

.main-banner-button {
  justify-content: center;
  padding: 0.75rem 1.5rem; /* 12px 24px */
  font-size: 1.1rem;
  color: #120349dd;
  background-color: #e0e0e0;
  border: none;
  border-radius: 0.625rem; /* 10px */
  transition: box-shadow 0.3s ease;
  margin-bottom: 0.625rem; /* 10px */
}

.main-banner-button:hover,
.main-banner-button:focus {
  color: #505050;
  box-shadow:
    inset 0.3125rem 0.3125rem 0.625rem #a7a7a7, /* 5px 5px 10px */
    inset -0.3125rem -0.3125rem 0.625rem #ffffff; /* -5px -5px 10px */
}

@keyframes floatBackground {
  0%, 100% { background-position: center 0; }
  50% { background-position: center 1%; }
}

/* Mobile */
@media (max-width: 576px) {
  .main-banner h1 {
    font-size: 2rem;
  }

  .main-banner p {
    font-size: 1rem;
    width: 100%;
  }

  .main-banner-button {
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
  }
}

/* Tablet */
@media (min-width: 577px) and (max-width: 1024px) {
  .main-banner h1 {
    font-size: 3rem;
    margin-bottom: 1.25rem; /* 20px */
  }

  .main-banner p {
    font-size: 1.5rem;
    margin-bottom: 1.875rem; /* 30px */
    width: 90%;
  }

  .main-banner-button {
    padding: 0.75rem 1.5rem; /* 12px 24px */
    font-size: 1rem;
  }
}

/* Laptop and Desktop */
@media (min-width: 1025px) {
  .main-banner h1 {
    font-size: 4.8rem;
    margin-bottom: 1.25rem; /* 20px */
  }

  .main-banner p {
    font-size: 1.8rem;
    margin-bottom: 2.5rem; /* 40px */
    width: 95%;
  }

  .main-banner-button {
    padding: 0.8rem 1.8rem;
    font-size: 1.3rem;
  }
}

/* Landscape Mode for Mobile */
@media (max-width: 576px) and (orientation: landscape) {
  .main-banner h1 {
    font-size: 1.5rem;
  }

  .main-banner p {
    font-size: 0.9rem;
    width: 80%;
  }

  .main-banner-button {
    padding: 0.4rem 1.2rem;
    font-size: 0.7rem;
  }
}

/* Landscape Mode for Tablets */
@media (min-width: 577px) and (max-width: 1024px) and (orientation: landscape) {
  .main-banner h1 {
    margin-top: 10%;
    font-size: 2.2rem;
    margin-bottom: 1rem; /* 16px */
  }

  .main-banner p {
    font-size: 0.8rem;
    margin-bottom: 1.5rem; /* 24px */
    width: 85%;
  }

  .main-banner-button {
    padding: 0.7rem 1.3rem; /* 11px 21px */
    font-size: 0.7rem;
  }
}
