.signup-banner {
  position: relative;
  text-align: center;
  background-image: url('../assets/img/bg1.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-banner-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Fill the parent's height */
}

.signup-banner h1 {
  font-size: 5rem; /* Large text */
  margin-bottom: 20px;
}

.signup-banner p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.signup-banner-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px; /* Spacing between buttons */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.signup-banner-button {
  justify-content: center;
  padding: 10px 20px;
  font-size: 1rem;
  color: #120349dd; /* A color that contrasts lightly with the background */
  background-color: #e0e0e0; /* Light background color typical for neumorphism */
  border: none;
  border-radius: 10px; /* Slightly rounded corners are common in neumorphism */
  box-shadow: 8px 8px 15px #a7a7a7; /* Dark shadow on the bottom right */
  transition: box-shadow 0.3s ease;
}

.signup-banner-button:hover,
.signup-banner-button:focus {
  color: #505050;
  /* Adjust the shadows for the clicked-in effect */
  box-shadow:
    inset 5px 5px 10px #a7a7a7,
    inset -5px -5px 10px #ffffff;
}

/* Mobile (up to 576px) */
@media (max-width: 576px) {
  .signup-banner-content {
    padding: 0 1.875rem; /* 30px */
  }

  .signup-banner h1 {
    font-size: 1.8rem;
    margin-bottom: 0.9375rem; /* 15px */
  }

  .signup-banner p {
    font-size: 1rem;
    margin-bottom: 1.5625rem; /* 25px */
  }

  .signup-banner-buttons {
    flex-direction: column;
    gap: 20px; /* Reduce gap between buttons when stacked */
  }

  .signup-banner-button {
    padding: 0.5rem 1.5rem;
    font-size: .8rem;
  }
}

/* Tablet (577px to 1024px) */
@media (min-width: 577px) and (max-width: 1024px) {
  .signup-banner h1 {
    font-size: 2.6rem;
    margin-bottom: 1.25rem; /* 20px */
  }

  .signup-banner p {
    font-size: 1.5rem;
    margin-bottom: 1.875rem; /* 30px */
    width: 85%;
  }

  .signup-banner-buttons {
    flex-direction: row; /* Side-by-side buttons on larger screens */
  }

  .signup-banner-button {
    padding: 0.75rem 1.5rem; /* 12px 24px */
    font-size: 1.1rem;
  }
}

/* Laptop and Desktop (1025px and above) */
@media (min-width: 1025px) {
  .signup-banner-content {
    padding: 0 5rem; /* 80px */
  }

  .signup-banner h1 {
    font-size: 4rem;
    margin-bottom: 1.25rem; /* 20px */
  }

  .signup-banner p {
    font-size: 1.8rem;
    margin-bottom: 2.5rem; /* 50px */
  }

  .signup-banner-button {
    padding: 0.8rem 1.8rem;
    font-size: 1.3rem;
  }
}

/* Landscape Mode for Mobile */
@media (max-width: 576px) and (orientation: landscape) {
  .signup-banner-content {
    padding: 0 1rem; /* 16px */
  }

  .signup-banner h1 {
    font-size: 1.6rem;
    margin-bottom: 0.8rem; /* 12.8px */
  }

  .signup-banner p {
    font-size: 0.9rem;
    margin-bottom: 1.25rem; /* 20px */
  }

  .signup-banner-buttons {
    flex-direction: column;
    gap: 15px; /* Reduce gap between buttons when stacked */
  }

  .signup-banner-button {
    padding: 0.4rem 1.2rem;
    font-size: 0.7rem;
  }
}

/* Landscape Mode for Tablets */
@media (min-width: 577px) and (max-width: 1024px) and (orientation: landscape) {
  .signup-banner h1 {
    margin-top: 10%;
    font-size: 2.2rem;
    margin-bottom: 1rem; /* 16px */
  }

  .signup-banner p {
    font-size: 1rem;
    margin-bottom: 1.5rem; /* 24px */
    width: 80%;
  }

  .signup-banner-buttons {
    flex-direction: row; /* Side-by-side buttons on larger screens */
  }

  .signup-banner-button {
    padding: 0.7rem 1.4rem; /* 11.2px 22.4px */
    font-size: 0.8rem;
  }
}
