/* Default styles */
.privacy-container {
  background-image: url('../assets/img/bg2.png');
  background-size: cover;
  color: #fff;
  min-height: 100vh;
  margin-top: 10rem;
  padding: 2rem;
}

.privacy-title {
  margin-bottom: 3rem;
  font-size: 3.5rem;
  color: #e9ecef;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  font-weight: bold;
}

.privacy-intro {
  font-size: 1.6rem;
  margin-bottom: 4rem;
  color: #dee2e6;
  opacity: 0.9;
}

.privacy-heading {
  font-size: 1.5rem;
  font-weight: bold;
}

.privacy-text {
  text-align: justify;
  max-width: 1200px;
  margin: auto;
  background: rgba(79, 23, 114, 0.06);
  border-radius: 10px;
  padding: 4rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
}

.table-responsive {
  margin: 2rem auto;
  overflow-x: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.data-table th,
.data-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
  color: white;
}

.data-table ul {
  padding-left: 20px;
}

.privacy-text p {
  margin-bottom: 2rem;
}

a {
  color: #17a2b8;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #138496;
}

/* Responsive styles */
@media (max-width: 768px) {
  .privacy-title {
    font-size: 2.5rem;
  }

  .privacy-intro {
    font-size: 1.2rem;
  }

  .privacy-heading {
    font-size: 1.2rem;
  }

  .privacy-text {
    padding: 2rem;
    font-size: 1rem;
  }

  .table-responsive {
    margin: 1rem auto;
  }

  .data-table th,
  .data-table td {
    padding: 6px;
  }
}

@media (max-width: 480px) {
  .privacy-title {
    font-size: 2rem;
  }

  .privacy-intro {
    font-size: 1rem;
  }

  .privacy-heading {
    font-size: 1rem;
  }

  .privacy-text {
    padding: 1rem;
    font-size: 0.9rem;
  }

  .table-responsive {
    margin: 1rem auto;
  }

  .data-table th,
  .data-table td {
    padding: 4px;
    font-size: 0.8rem;
  }
}

/* Desktop styles */
@media (min-width: 1200px) {
  .privacy-container {
    padding: 4rem;
  }

  .privacy-title {
    font-size: 4rem;
  }

  .privacy-intro {
    font-size: 2rem;
  }

  .privacy-heading {
    font-size: 1.8rem;
  }

  .privacy-text {
    padding: 5rem;
    font-size: 1.4rem;
  }
}