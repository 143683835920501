/* BountyHunterPage.css */
.signup-page {
  width: 100%;
  min-height: max-content;
  /* Set the color scheme according to your design */
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: center center; /* Centers the image in the section */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  /* Example background color */
}
