/* BountyHunterPage.css */
.bounty-hunter-page {
  width: 100%;
  min-height: max-content;
  background-size: cover;
  color: #ffffff; /* Adjust the text color according to your background for better visibility */
}

.welcome-message {
  text-align: center;
  padding: 50px; /* Default padding */
  font-size: 1.5em; /* Default font size */
  color: inherit; /* Inherits color from parent if not specified */
}

/* Adjustments for extra small devices (phones, less than 576px) */
@media (max-width: 576px) {
  .bounty-hunter-page {
    min-height: 60vh; /* Smaller height to accommodate device size */
  }

  .welcome-message {
    padding: 20px; /* Less padding on small devices */
    font-size: 1em; /* Smaller font size for smaller screens */
  }
}

/* Adjustments for small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 768px) {
  .bounty-hunter-page {
    min-height: 70vh;
  }

  .welcome-message {
    padding: 30px;
    font-size: 1.2em;
  }
}

/* Adjustments for medium devices (small laptops, 769px to 992px) */
@media (min-width: 769px) and (max-width: 992px) {
  .bounty-hunter-page {
    min-height: 80vh;
  }

  .welcome-message {
    padding: 40px;
    font-size: 1.4em;
  }
}

/* Adjustments for large devices (desktops, 993px and above) */
@media (min-width: 993px) {
  .bounty-hunter-page {
    min-height: 90vh;
  }

  .welcome-message {
    padding: 50px; /* Reverting to default padding */
    font-size: 1.5em; /* Default font size */
  }
}
