.mission-vision-section {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  width: 100%;
  padding: 0;
  margin: 0;
  background-image: url('../assets/img/bg2.png');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.mission-vision-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mission-vision-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mission, .vision {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem; /* 20px */
  text-align: center;
  height: 100%;
}

.mission img, .vision img {
  width: 40%;
  height: auto;
  margin: 0 auto 1.25rem auto; /* 20px */
}

.mission-title, .vision-title {
  font-weight: bold;
  font-size: 1.5rem; /* Mobile-first font size */
  margin-bottom: 1.25rem; /* 20px */
}

.mission-title span, .vision-title span {
  color: inherit;
}

.mission-title span {
  color: #ff6b6b;
}

.vision-title span {
  color: #6bcfff;
}

.mission p, .vision p,
.mission ul, .vision ul {
  font-size: 1rem; /* Mobile-first font size */
  line-height: 1.5;
  margin: 0;
}

.mission ul, .vision ul {
  list-style-type: disc;
  padding-left: 1.25rem; /* 20px */
  text-align: left;
}

/* Mobile (up to 576px) */
@media (max-width: 576px) {
  .mission-vision-section {
    padding: 3rem 0;
  }
  
  .mission, .vision {
    padding: 1.25rem; /* 20px */
    margin: 1.25rem 0rem; /* 20px 10px */
  }

  .mission img, .vision img {
    width: 50%;
    height: auto;
  }

  .mission-title, .vision-title {
    font-size: 1.5rem !important;
    text-align: center;
  }

  .mission-title span, .vision-title span {
    font-size: 2rem;
  }

  .mission p, .vision p,
  .mission ul, .vision ul {
    font-size: 1rem;
  }
}

/* Tablet (577px to 1024px) */
@media (min-width: 577px) and (max-width: 1024px) {
  .mission, .vision {
    padding: 0.9375rem; /* 15px */
    margin: 0.9375rem; /* 15px */
  }

  .mission img, .vision img {
    width: 40%;
    height: auto;
  }

  .mission-title, .vision-title {
    font-size: 2rem;
  }

  .mission-title span, .vision-title span {
    font-size: 2.6rem;
  }

  .mission p, .vision p,
  .mission ul, .vision ul {
    font-size: 1.5rem;
  }

  .mission-section {
    justify-content: center;
    padding: 8rem 0;
  }
}

/* Laptop and Desktop (1025px and above) */
@media (min-width: 1025px) {
  .mission-vision-section {
    height: 100vh; /* Full height of the viewport */
    padding: 0; /* Reset padding */
    display: flex;
    background-size: cover; /* Cover the full area without being cut off */
  }

  .mission-vision-container {
    padding: 0 1.875rem; /* 30px */
  }

  .mission-vision-row {
    flex-direction: row; /* Keep row layout */
  }

  .mission, .vision {
    align-items: center; /* Aligns items to the left */
    text-align: center; /* Text align left */
  }

  .mission img, .vision img {
    width: 40%;
    height: auto;
  }

  .mission-title span, .vision-title span {
    font-size: 2.6rem;
  }

  .mission p, .vision p,
  .mission ul, .vision ul {
    font-size: 1.6rem;
  }
}


/* Landscape Mode for Mobile */
@media (max-width: 576px) and (orientation: landscape) {
  .mission-vision-section {
    padding: 20rem 0 !important;
  }
  
  .mission, .vision {
    padding: 1rem; /* 16px */
    margin: 1rem 0.5rem; /* 16px 8px */
  }

  .mission img, .vision img {
    width: 40%;
    height: auto;
  }

  .mission-title, .vision-title {
    font-size: 1.2rem !important;
    text-align: center;
  }

  .mission-title span, .vision-title span {
    font-size: 1.5rem;
  }

  .mission p, .vision p,
  .mission ul, .vision ul {
    font-size: 0.9rem;
  }
}

/* Landscape Mode for Tablets */
@media (min-width: 577px) and (max-width: 1024px) and (orientation: landscape) {  
  .mission, .vision {
    padding: 0.875rem; /* 14px */
    margin: 0.875rem; /* 14px */
  }

  .mission img {
    width: 30%;
    height: auto;
  }

  .vision img {
    width: 30%;
  }

  .mission-title, .vision-title {
    font-size: 1.8rem;
  }

  .mission-title span {
    font-size: 1.5rem;
  }

  .vision-title span {
    font-size: 1.5rem;

  }

  .mission p, .vision p,
  .mission ul, .vision ul {
    font-size: 0.9rem;
  }
}
