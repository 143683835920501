.contact-section {
  background-image: url('../assets/img/bg1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 1.25rem; /* 20px, Initial padding for mobile-first design */
  color: #FFFFFF; /* White text color */
  display: flex;
  align-items: center; /* Centers content vertically */
  height: auto;
  min-height: 100vh; /* At least full height */
}

.contact-content {
  width: 100%;
  max-width: 62.5rem; /* 1000px, Max width can be adjusted to your preference */
  margin: 0 auto; /* Centers the container */
  padding: 0 0.9375rem; /* 15px, Padding on the sides */
}

.contact-text {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Centers content horizontally on small screens */
  justify-content: center; /* Center items vertically for a full-height column */
  text-align: center; /* Center text alignment */
}

.contact-text h1 {
  font-size: 1.5rem; /* Smaller font size for headings on mobile */
  margin-bottom: 1rem;
  font-weight: bold;
}

.contact-text p {
  font-size: 1rem; /* Mobile-first font size */
  margin-bottom: 1rem; /* Space between paragraphs */
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  background-color: #FFFFFF;
  padding: 2rem;
  border-radius: 0.25rem; /* 4px */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 1rem; /* Standard spacing */
  padding: 0.8rem 1rem; /* 0.8rem 1rem */
  border: 0.125rem solid #e3e6f0; /* 2px, Slightly thicker border for focus */
  border-radius: 0.5rem; /* 8px, Updated border radius */
  font-size: 1rem; /* Standard font size */
  background-color: #fafafa; /* Slightly off white for inputs to pop out */
  transition: border 0.3s ease, box-shadow 0.3s ease; /* Adding transition for shadow */
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #0056b3;
  outline: none;
}

.contact-form button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1.25rem; /* 10px 20px */
  font-size: 1rem; /* 16px */
  color: #FFFFFF;
  background-color: #007BFF;
  border: none;
  border-radius: 0.25rem; /* 4px */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #0056b3;
}

.contact-content {
  display: flex;
}

.success-message {
  color: #000000;
  margin-top: .5rem;
}

/* Mobile Portrait */
@media (max-width: 576px) {
  .contact-section {
    text-align: center;
    padding: 7rem 1rem;
  }
  .contact-content {
    flex-direction: column;
    align-items: center;
  }
  .contact-text h1 {
    font-size: 1.5rem;
    width: 80%;
  }
  .contact-text p {
    width: 90%;
    margin-bottom: 3rem;
  }
  .contact-form {
    width: 95%;
  }
}

/* Tablet Portrait */
@media (min-width: 577px) and (max-width: 1024px) {
  .contact-section {
    text-align: center;
    padding: 1rem;
  }
  .contact-content {
    flex-direction: column;
    align-items: center;
  }
  .contact-text h1 {
    font-size: 2.2rem;
    width: 70%;
  }
  .contact-text p {
    width: 80%;
    margin-bottom: 3rem;
    font-size: 1.2rem;
  }
  .contact-form {
    width: 90%;
  }
}

/* Laptop and Desktop */
@media (min-width: 1025px) {
  .contact-section {
    height: 100vh;
    padding: 0;
    display: flex;
    align-items: center;
  }
  .contact-content {
    flex-direction: row;
    justify-content: space-between;
  }
  .contact-text {
    text-align: left;
    align-items: flex-start;
    width: 50%;
  }
  .contact-text h1 {
    font-size: 2.5rem;
  }
  .contact-text p {
    font-size: 1.3rem;
  }
  .contact-form {
    align-items: flex-start;
    width: 45%;
  }
  .contact-form form {
    width: 100%;
    max-width: 500px;
  }
}

/* Landscape Mode for Mobile */
@media (max-width: 576px) and (orientation: landscape) {
  .contact-section {
    text-align: center;
    padding: 4rem 1rem;
  }
  .contact-content {
    flex-direction: column;
    align-items: center;
  }
  .contact-text h1 {
    font-size: 1.2rem;
    width: 80%;
  }
  .contact-text p {
    width: 90%;
    margin-bottom: 2rem;
    font-size: 0.9rem;
  }
  .contact-form {
    width: 90%;
  }
}

/* Landscape Mode for Tablets */
@media (min-width: 577px) and (max-width: 1024px) and (orientation: landscape) {
  .contact-section {
    text-align: center;
    padding: 2rem;
  }
  .contact-content {
    flex-direction: column;
    align-items: center;
  }
  .contact-text h1 {
    font-size: 1.5rem;
    width: 70%;
  }
  .contact-text p {
    width: 80%;
    margin-bottom: 2rem;
    font-size: 0.9rem;
  }
  .contact-form {
    width: 85%;
  }
}
