.about-us-page {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
}

/* Adjustments for small screens (up to 576px) */
@media (max-width: 576px) {
  .about-us-page {
    min-height: 50vh; /* Less height for smaller screens */
    background-size: auto 100%; /* Adjust background size */
  }
}

/* Adjustments for medium screens (577px to 768px) */
@media (min-width: 577px) and (max-width: 768px) {
  .about-us-page {
    min-height: 70vh;
  }
}

/* Adjustments for large screens (769px to 992px) */
@media (min-width: 769px) and (max-width: 992px) {
  .about-us-page {
    min-height: 80vh;
  }
}

/* Adjustments for extra-large screens (993px and above) */
@media (min-width: 993px) {
  .about-us-page {
    min-height: 100vh; /* Full height for large screens */
  }
}
