.ai-builder-section {
  background-image: url('../assets/img/bg1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 1.25rem; /* 20px, Initial padding for mobile-first design */
  color: #ffffff; /* White text color */
  display: flex;
  align-items: center; /* Centers content vertically */
  height: auto;
  min-height: 100vh; /* At least full height */
}

.ai-builder-section .container {
  width: 100%;
  max-width: 62.5rem; /* 1000px, Max width can be adjusted to your preference */
  margin: 0 auto; /* Centers the container */
  padding: 0 0.9375rem; /* 15px, Padding on the sides */
}

.ai-builder-section .row {
  width: 100%;
}

.ai-builder-section .col-md-6 {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Centers content horizontally on small screens */
  justify-content: center; /* Center items vertically for a full-height column */
  text-align: center; /* Center text alignment */
}

.ai-builder-section h2 {
  font-size: 1.5rem; /* Smaller font size for headings on mobile */
  margin-bottom: 1rem;
  font-weight: bold;
}

.ai-builder-section .benefits {
  list-style: none; /* Removes the default list styling */
  padding-left: 1.25rem; /* 20px, Adjust padding to accommodate checkmark */
  margin: 0 0 2rem 0; /* Space below the list */
}

.ai-builder-section .benefits li {
  font-size: 0.9rem; /* Mobile-first font size */
  margin-bottom: 0.5rem; /* Space between list items */
  position: relative; /* Needed for absolute positioning of the checkmark */
}

.ai-builder-section .benefits li::before {
  content: '✓'; /* Adds the checkmark */
  position: absolute;
  left: -1.25rem; /* -20px, Position the checkmark correctly relative to the text */
  color: #0f9d58; /* The color of your checkmark */
  font-weight: bold; /* Makes the checkmark bold */
}

.ai-builder-section .btn-ai-builders {
  color: #120349dd;
  background-color: #e0e0e0;
  border: none;
  border-radius: 0.625rem; /* 10px */
  transition: box-shadow 0.3s ease;
  margin: 0 auto; /* Center the button */
  width: 12rem;
  justify-content: center;
}

.ai-builder-section .btn-ai-builders:hover {
  color: #505050;
  box-shadow:
    inset 0.3125rem 0.3125rem 0.625rem #a7a7a7, /* 5px 5px 10px */
    inset -0.3125rem -0.3125rem 0.625rem #ffffff; /* -5px -5px 10px */
}

.benefits {
  text-align: left !important;
}

/* Mobile Portrait */
@media (max-width: 576px) { /* 35.9375rem */
  .ai-builder-section {
    text-align: center; /* Center content on mobile */
  }
  
  .ai-builder-section .col-md-6 {
    align-items: center; /* Center items horizontally */
    margin-bottom: 1rem; /* Add some space between columns */
  }

  .ai-builder-section .container,
  .ai-builder-section .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ai-builder-section .col-md-6 img {
    margin-bottom: 1rem;
  }

  .ai-builder-section .text-md-left {
    order: 2;
  }

  .ai-builder-section img {
    order: 1;
  }

  .ai-builder-section h2 {
    text-align: center; /* Center text on mobile */
  }

  .ai-builder-section .btn-ai-builders {
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
    width: 12rem;
  }
}

/* Tablet Portrait */
@media (min-width: 577px) and (max-width: 1024px) { /* 36rem to 64rem */
  .ai-builder-section {
    text-align: center; /* Center content on tablet */
  }
  
  .ai-builder-section .col-md-6 {
    align-items: center; /* Center items horizontally */
    margin-bottom: 1rem; /* Add some space between columns */
  }

  .ai-builder-section .container,
  .ai-builder-section .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ai-builder-section .col-md-6 img {
    margin-bottom: 1rem;
  }

  .ai-builder-section .text-md-left {
    order: 2;
  }

  .ai-builder-section img {
    order: 1;
  }

  .ai-builder-section h2 {
    font-size: 2rem; /* Larger font size for tablet */
    text-align: center; /* Center text on tablet */
  }

  .ai-builder-section .btn-ai-builders {
    width: 10rem;
  }

  .ai-builder-section .benefits li {
    font-size: 1rem; /* Larger font size for tablet */
  }

  .ai-builder-section .benefits li::before {
    left: -1.5rem; /* -24px, Positions the checkmark to the left of the text */
  }

  .ai-builder-section .btn-ai-builders {
    padding: 0.7rem 1.7rem;
    height: auto;
    justify-content: center;
    font-size: 1rem;
    width: 14rem;
  }
}

/* Laptop and Desktop */
@media (min-width: 1025px) { /* 64.0625rem */
  .ai-builder-section {
    height: 100vh; /* Full height of the viewport */
    padding: 0; /* Reset padding */
    display: flex;
    background-size: cover; /* Cover the full area without being cut off */
  }

  .ai-builder-section .row {
    flex-direction: row; /* Keep row layout */
  }

  .ai-builder-section h2 {
    font-size: 2.5rem; /* Larger screens font size */
    text-align: left; /* Align text to the left */
  }

  .ai-builder-section .col-md-6 {
    align-items: flex-start; /* Aligns items to the left */
    text-align: left; /* Text align left */
  }

  .ai-builder-section .btn-ai-builders {
    width: 10rem;
    height: auto;
    justify-content: center;
    margin-left: 0; /* Align button to the left edge */
  }

  .ai-builder-section .benefits li {
    font-size: 1.2rem; /* Larger screens font size */
  }

  .ai-builder-section .benefits li::before {
    left: -1.5625rem; /* -25px, Positions the checkmark to the left of the text */
  }

  .ai-builder-section .btn-ai-builders {
    padding: 0.7rem 1.7rem;
    height: auto;
    justify-content: center;
    margin-left: 0; /* Align button to the left edge */
    font-size: 1rem;
  }

  .ai-builder-section .btn-ai-builders {
    width: 14rem;
    justify-content: center;
  }
}

/* Landscape Mode for Mobile */
@media (max-width: 576px) and (orientation: landscape) {
  .ai-builder-section {
    text-align: center; /* Center content on mobile landscape */
  }
  
  .ai-builder-section .col-md-6 {
    align-items: center; /* Center items horizontally */
    margin-bottom: 1rem; /* Add some space between columns */
  }

  .ai-builder-section .container,
  .ai-builder-section .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ai-builder-section .col-md-6 img {
    margin-bottom: 1rem;
  }

  .ai-builder-section .text-md-left {
    order: 2;
  }

  .ai-builder-section img {
    order: 1;
  }

  .ai-builder-section h2 {
    font-size: 1.2rem; /* Smaller font size for mobile landscape */
    text-align: center; /* Center text */
  }

  .ai-builder-section .btn-ai-builders {
    padding: 0.4rem 1.2rem;
    font-size: 0.7rem;
    width: 12rem;
  }

  .ai-builder-section .benefits li {
    font-size: 0.8rem; /* Smaller font size for mobile landscape */
  }

  .ai-builder-section .benefits li::before {
    left: -1.25rem; /* -20px, Position the checkmark correctly relative to the text */
  }
}

/* Landscape Mode for Tablets */
@media (min-width: 577px) and (max-width: 1024px) and (orientation: landscape) {
  .ai-builder-section {
    text-align: center; /* Center content on tablet landscape */
  }
  
  .ai-builder-section .col-md-6 {
    align-items: center; /* Center items horizontally */
    margin-bottom: 1rem; /* Add some space between columns */
  }

  .ai-builder-section .container,
  .ai-builder-section .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ai-builder-section .col-md-6 img {
    margin-bottom: 1rem;
  }

  .ai-builder-section .text-md-left {
    order: 2;
  }

  .ai-builder-section img {
    width: 60%;
    order: 1;
  }

  .ai-builder-section h2 {
    font-size: 1.5rem; /* Smaller font size for tablet landscape */
    text-align: center; /* Center text */
  }

  .ai-builder-section .btn-ai-builders {
    padding: 0.6rem 1.5rem;
    height: auto;
    justify-content: center;
    font-size: 0.9rem;
    width: 12rem;
    margin-top: -10px;
  }

  .ai-builder-section .benefits li {
    font-size: 0.9rem; /* Smaller font size for tablet landscape */
  }

  .ai-builder-section .benefits li::before {
    left: -1.25rem; /* -20px, Position the checkmark correctly relative to the text */
  }
}
