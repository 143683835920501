.data-collect-section {
  background-image: url('../assets/img/bg1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 1.25rem; /* 20px, Initial padding for mobile-first design */
  color: #FFFFFF; /* White text color */
  display: flex;
  align-items: center; /* Centers content vertically */
  height: auto;
  min-height: 100vh; /* At least full height */
}

.data-collect-section .container {
  width: 100%;
  max-width: 62.5rem; /* 1000px, Max width can be adjusted to your preference */
  margin: 0 auto; /* Centers the container */
  padding: 0 0.9375rem; /* 15px, Padding on the sides */
}

.data-collect-section .row {
  width: 100%;
}

.data-collect-section .col-md-6 {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Centers content horizontally on small screens */
  justify-content: center; /* Center items vertically for a full-height column */
  text-align: center; /* Center text alignment */
}

.data-collect-section h2 {
  font-size: 1.5rem; /* Smaller font size for headings on mobile */
  margin-bottom: 1rem;
  font-weight: bold;
}

.data-collect-section p {
  font-size: 1rem; /* Mobile-first font size */
  margin-bottom: 1rem; /* Space between paragraphs */
}

/* Original button styles retained */
.data-collect-section button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1.25rem; /* 10px 20px */
  font-size: 1rem; /* 16px */
  color: #FFFFFF;
  background-color: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 0.625rem; /* 10px */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.data-collect-section button:hover {
  background-color: #FFFFFF;
  color: #000000;
}

/* Mobile Portrait */
@media (max-width: 576px) { /* 35.9375rem */
  .data-collect-section {
    text-align: center; /* Center content on mobile */
  }
  .data-collect-section .col-md-6 {
    align-items: center; /* Center items horizontally */
    margin-bottom: 1rem; /* Add some space between columns */
  }
  .data-collect-section .container,
  .data-collect-section .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .data-collect-section .col-md-6 img {
    margin-bottom: 1rem;
  }
  .data-collect-section .text-md-left {
    order: 2;
  }
  .data-collect-section img {
    order: 1;
  }
  .data-collect-section h2 {
    text-align: center; /* Center text on mobile */
  }
}

/* Tablet Portrait */
@media (min-width: 577px) and (max-width: 1024px) { /* 36rem to 64rem */
  .data-collect-section {
    text-align: center; /* Center content on tablet */
  }
  .data-collect-section .col-md-6 {
    align-items: center; /* Center items horizontally */
    margin-bottom: 1rem; /* Add some space between columns */
  }
  .data-collect-section .container,
  .data-collect-section .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .data-collect-section .col-md-6 img {
    margin-bottom: 1rem;
  }
  .data-collect-section .text-md-left {
    order: 2;
  }
  .data-collect-section img {
    order: 1;
  }
  .data-collect-section h2 {
    font-size: 2rem; /* Larger font size for tablet */
    text-align: center; /* Center text on tablet */
  }
  .data-collect-section p {
    font-size: 1.1rem; /* Larger font size for tablet */
  }
}

/* Laptop and Desktop */
@media (min-width: 1025px) { /* 64.0625rem */
  .data-collect-section {
    height: 100vh; /* Full height of the viewport */
    padding: 0; /* Reset padding */
    display: flex;
    background-size: cover; /* Cover the full area without being cut off */
  }
  .data-collect-section .row {
    flex-direction: row; /* Keep row layout */
  }
  .data-collect-section h2 {
    font-size: 2.5rem; /* Larger screens font size */
    text-align: left; /* Align text to the left */
  }
  .data-collect-section .col-md-6 {
    align-items: flex-start; /* Aligns items to the left */
    text-align: left; /* Text align left */
  }
  .data-collect-section p {
    font-size: 1.2rem; /* Larger screens font size */
  }
}

/* Landscape Mode for Mobile */
@media (max-width: 576px) and (orientation: landscape) {
  .data-collect-section {
    text-align: center; /* Center content on mobile landscape */
  }
  .data-collect-section .col-md-6 {
    align-items: center; /* Center items horizontally */
    margin-bottom: 1rem; /* Add some space between columns */
  }
  .data-collect-section .container,
  .data-collect-section .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .data-collect-section .col-md-6 img {
    margin-bottom: 1rem;
  }
  .data-collect-section h2 {
    font-size: 1.2rem; /* Smaller font size for mobile landscape */
    text-align: center; /* Center text */
  }
  .data-collect-section p {
    font-size: 0.9rem; /* Smaller font size for mobile landscape */
  }
  .data-collect-section button {
    padding: 0.4rem 1.2rem;
    font-size: 0.7rem;
  }
}

/* Landscape Mode for Tablets */
@media (min-width: 577px) and (max-width: 1024px) and (orientation: landscape) {
  .data-collect-section {
    text-align: center; /* Center content on tablet landscape */
  }
  .data-collect-section .col-md-6 {
    align-items: center; /* Center items horizontally */
    margin-bottom: 1rem; /* Add some space between columns */
  }
  .data-collect-section .container,
  .data-collect-section .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .data-collect-section .col-md-6 img {
    width: 60%;
    margin-bottom: -1.2rem;
  }
  .data-collect-section h2 {
    font-size: 1.5rem; /* Smaller font size for tablet landscape */
    text-align: center; /* Center text */
  }
  .data-collect-section p {
    font-size: 0.9rem; /* Smaller font size for tablet landscape */
  }
  .data-collect-section button {
    padding: 0.6rem 1.5rem;
    font-size: 0.9rem;
  }
}
