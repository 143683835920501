.steps-section {
  background-image: url('../assets/img/bg2.png');
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  justify-content: space-around;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.25rem 0; /* 20px */
}

.step-icon {
  width: 60%; /* Initial mobile-first width */
  height: auto;
  margin-bottom: 0.625rem; /* 10px */
}

.step h3 {
  margin: 0.625rem 0 0.3125rem; /* 10px 0 5px */
  font-weight: 900;
  font-size: 1.9rem
}

.step p {
  text-align: center;
  margin: 0 0.625rem; /* 0 10px */
}

/* Mobile Portrait (up to 576px) */
@media (max-width: 576px) {
  .steps-section {
    flex-direction: column;
  }

  .step h3 {
    font-size: 1.2rem; /* Mobile-first font size */
  }

  .step p {
    font-size: 0.875rem; /* Mobile-first paragraph size */
    width: 80%;
  }

  .step-icon {
    width: 30%; /* Adjust icon size for larger screens */
  }
}

/* Tablet Portrait (577px to 1024px) */
@media (min-width: 577px) and (max-width: 1024px) {
  .steps-section {
    flex-direction: row;
    justify-content: space-around;
    padding: 3.125rem; /* 50px */
  }

  .step {
    width: 80%; /* Adjust width for better spacing on larger screens */
    margin: 0 0.625rem; /* 0 10px */
  }

  .step-icon {
    width: 40%; /* Adjust icon size for larger screens */
  }

  .step p {
    margin: 0 1.25rem; /* 0 20px */
  }
}

/* Laptop and Desktop (1025px and above) */
@media (min-width: 1025px) {
  .steps-section {
    padding: 3.125rem 5rem; /* 50px 80px */
  }

  .step {
    width: 25%; /* Slightly reduce step width for more space */
  }

  .step h3 {
    font-size: 2rem; /* Increase font size for headings on large screens */
  }

  .step p {
    font-size: 1.1rem; /* Increase paragraph font size for readability */
    font-weight: normal;
  }
}

/* Landscape Mode for Mobile */
@media (max-width: 576px) and (orientation: landscape) {
  .steps-section {
    flex-direction: row;
    justify-content: space-around;
    padding: 2rem; /* Adjust padding for landscape mode */
  }

  .step {
    width: 30%; /* Adjust width for better spacing in landscape mode */
    margin: 0 1rem; /* Adjust margin */
  }

  .step-icon {
    width: 30%; /* Adjust icon size for landscape mode */
  }

  .step h3 {
    font-size: 0.9rem; /* Adjust font size for landscape mode */
  }

  .step p {
    font-size: 0.75rem; /* Adjust paragraph font size for landscape mode */
    width: 90%;
  }
}

/* Landscape Mode for Tablets */
@media (min-width: 577px) and (max-width: 1024px) and (orientation: landscape) {
  .steps-section {
    flex-direction: row;
    justify-content: space-around;
    padding: 3rem; /* Adjust padding for landscape mode */
  }

  .step {
    width: 25%; /* Adjust width for better spacing in landscape mode */
    margin: 0 1.5rem; /* Adjust margin */
  }

  .step-icon {
    width: 60%; /* Adjust icon size for landscape mode */
  }

  .step h3 {
    font-size: 1.2rem; /* Adjust font size for landscape mode */
  }

  .step p {
    font-size: 0.6rem; /* Adjust paragraph font size for landscape mode */
    width: 85%;
  }
}
