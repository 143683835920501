/* General Styles for the Team Section */
.team-section {
  background-image: url('../assets/img/bg1.png');
  text-align: center;
  padding: 0px 60px 200px 60px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team-section-heading {
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 2rem;
}

.team-members-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.team-member {
  width: calc(33.333% - 40px);
  margin: 5px;
}

.team-member-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.icon-pdf,
.icon-linkedin {
  font-size: 24px;
  color: white;
  margin: 0 10px;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
}

.icon-pdf:hover {
  color: #04ff00;
}

.icon-linkedin:hover {
  color: #0056b3;
}

.team-member-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 3px solid white;
}

.team-member-name {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 5px;
  height: 2rem;
}

.team-member-title {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 10px;
  height: 2rem;
}

.team-member-description {
  color: #fff;
  margin-bottom: 0.5rem;
}

/* Responsive design for smaller screens */
@media (max-width: 1200px) {
  .team-member {
    width: calc(33.333% - 40px); /* 3 in a row */
  }
}

@media (max-width: 992px) {
  .team-member {
    width: calc(50% - 40px); /* 2 in a row */
  }
}

@media (max-width: 768px) {
  .team-section {
    padding: 60px 0;
  }
  
  .team-member {
    width: calc(50% - 40px); /* 2 in a row */
  }
}

@media (max-width: 576px) {
  .team-member {
    width: calc(100% - 20px); /* Full width on very small screens */
    padding: 20px 0;
  }

  .team-member-image {
    width: 120px;
    height: 120px;
  }

  .team-section-heading {
    font-size: 2rem;
    margin-bottom: 1.5rem !important;
  }
}

/* Tablet (577px to 1024px) */
@media (min-width: 577px) and (max-width: 1024px) {
  .team-member {
    margin-bottom: 3rem;
  }

  .team-members-container {
    gap: 0;
  }
}

/* Landscape Mode for Mobile */
@media (max-width: 576px) and (orientation: landscape) {
  .team-member {
    width: calc(50% - 20px); /* 2 in a row for mobile landscape */
    padding: 10px 0;
  }

  .team-member-image {
    width: 100px;
    height: 100px;
  }

  .team-section-heading {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}

/* Landscape Mode for Tablets */
@media (min-width: 577px) and (max-width: 1024px) and (orientation: landscape) {
  .team-member {
    width: calc(33.333% - 30px); /* 3 in a row for tablet landscape */
  }

  .team-member-name {
    font-size: 1.2rem;
  }

  .team-member-title {
    font-size: 0.9rem;
  }

  .team-member-image {
    width: 90px;
    height: 90px;
  }

  .team-section-heading {
    margin-top: 2rem;
    font-size: 2rem;
    margin-bottom: 2.5rem;
  }
}
